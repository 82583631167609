import request from './request'

// 登录
export const login = (data) => {
  console.log(process.env)
  return request({
    url: '/user/login',
    method: 'POST',
    data
  })
}
