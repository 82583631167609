import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-85669f08"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "svg-icon",
  "aria-hidden": "true"
};
const _hoisted_2 = ["xlink:href"];
import { computed } from 'vue';
export default {
  __name: 'index',
  props: {
    icon: {
      type: String,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    const iconName = computed(() => {
      return `#icon-${props.icon}`;
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("svg", _hoisted_1, [_createElementVNode("use", {
        "xlink:href": _unref(iconName)
      }, null, 8, _hoisted_2)]);
    };
  }
};