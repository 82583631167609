import axios from 'axios'
import { ElMessage } from 'element-plus'
import { diffTokenTime } from '@/util/auth'
import store from '@/store'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    if (localStorage.getItem('token')) {
      if (diffTokenTime()) {
        store.dispatch('app/logout')
        return Promise.reject(new Error('token 失效了'))
      }
    }
    config.headers.Authorization = localStorage.getItem('token')
    return config
  },
  (error) => {
    return Promise.reject(new Error(error))
  }
)

// 响应拦截器
service.interceptors.response.use((response) => {
    const { code, msg, data } = response.data
    if (code == '200') {
      return data
    }  else if (!code){ // 下载
      return response;
    }else {
      ElMessage.error(msg)
      return Promise.reject(new Error(msg))
    }
  },
  (error) => {
    error.response && ElMessage.error(error.response.msg?error.response.msg:error.response.statusText)
    return Promise.reject(new Error(error.response.msg?error.response.msg:error.response.statusText))
  }
)

export default service
