export default {
  menus: {
    // menu
    '/': 'home',
    '/home': 'home',
    '/worker': 'worker list',
    // breadcrumb
    home: 'home',
    worker: 'user list'
  },
  login: {
    title: 'user login',
    btnTitle: 'login'
  },
  userDialog: {
    deleteTitle: 'Are you sure you want to delete the user '
  },
  userTable: {
    userName: 'userName',
    realName: 'realName',
    email: 'email',
    mobile: 'mobile',
    jobName: 'jobName',
    userStatus: 'userStatus',
    createTime: 'createTime',
    action: 'action',
    search: 'search',
    adduser: 'add user',
    placeholder: 'Please enter a name to search for'
  },
  userMessage: {
    updateSuccess: 'update successfully'
  },
  driver: {
    doneBtnText: 'done',
    closeBtnText: 'close',
    nextBtnText: 'next',
    prevBtnText: 'prev',
    guideBtn: 'guidebtn',
    hamburgerBtn: 'hamburgerBtn',
    fullScreen: 'fullScreen'
  },
  meizhuangTable: {
    id: 'id',
    name: 'name',
    weixin: 'weixin',
    source: 'source',
    mobile: 'mobile',
    sex: 'sex',
    age: 'age',
    douyin: 'douyin',
    address: 'address',
    skin: 'skin',
    toiletry: 'toiletry',
    ip:'ip',
    ipRegion:'ipRegion',
    userStatus: 'userStatus',
    createTime: 'createTime',
    action: 'action',
    search: 'search',
    exportdata: 'exportdata',
    placeholder: 'placeholder'
  },
}
