export default {
  menus: {
    // 菜单
    '/': '首页',
    '/home': '首页',
    '/worker': '工作报名列表',
    // 面包屑
    home: '首页',
    worker: '工作报名列表'
  },
  login: {
    title: '用户登录',
    btnTitle: '登录'
  },
  userDialog: {
    deleteTitle: '确定要删除用户'
  },
  userTable: {
    id: '序号',
    name: '用户名',
    weixin: '微信',
    source: '来源',
    mobile: '手机',
    sex: '年龄',
    ip:'ip地址',
    ipRegion:'ip归属',
    userStatus: '状态',
    createTime: '创建时间',
    action: '操作',
    search: '搜索',
    exportdata: '数据导出',
    placeholder: '请输入搜索的名称'
  },
  userMessage: {
    updateSuccess: '更新成功'
  },
  driver: {
    doneBtnText: '完成',
    closeBtnText: '关闭',
    nextBtnText: '下一步',
    prevBtnText: '上一步',
    guideBtn: '引导按钮',
    hamburgerBtn: '汉堡按钮',
    fullScreen: '全屏按钮'
  },
  meizhuangTable: {
    id: '序号',
    name: '用户名',
    weixin: '微信',
    source: '来源',
    mobile: '手机',
    sex: '性别',
    age: '年龄',
    douyin: '抖音',
    address: '详细地址',
    skin: '皮肤类型',
    toiletry: '用过的化妆品',
    ip:'ip地址',
    ipRegion:'ip归属',
    userStatus: '状态',
    createTime: '创建时间',
    action: '操作',
    search: '搜索',
    exportdata: '数据导出',
    placeholder: '请输入搜索的名称'
  },
}
