const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc');

// 时间戳转换为时间
const filterTimes = (val, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!isNull(val)) {
    val = parseInt(val) * 1000
    return dayjs(val).format(format)
  } else {
    return '-'
  }
}

// UTC时间转换为时间
const filterUTCtimes = (val, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!isNull(val)) {
    dayjs.extend(utc);
    return dayjs.utc(val).format(format);
  } else {
    return '-'
  }
}

export const isNull = (date) => {
  if (!date) return true
  if (JSON.stringify(date) === '{}') return true
  if (JSON.stringify(date) === '[]') return true
}

export default (app) => {
  app.config.globalProperties.$filters = {
    filterTimes,filterUTCtimes
  }
}