import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login')
  },
  {
    path: '/',
    name: '/',
    component: () => import('../layout'),
    redirect: '/worker', // 默认 to children.name
    children: [
      {
        path: '/worker',
        name: 'worker',
        component: () => import('@/views/worker/index.vue')
      },
      {
        path: '/meizhuang',
        name: 'meizhuang',
        component: () => import('@/views/meizhuang/index.vue')
      },
      {
        path: '/role',
        name: '角色管理',
        component: () => import('@/views/role/index.vue')
      },
      {
        path: '/perm',
        name: '权限管理',
        component: () => import('@/views/perm/index.vue')
      },
      {
        path: '/account',
        name: '账号管理',
        component: () => import('@/views/account/index.vue')
      },
      {
        path: '/goods',
        name: '商品管理',
        component: () => import('@/views/goods/index.vue')
      },
      {
        path: '/order',
        name: '订单管理',
        component: () => import('@/views/order/index.vue')
      },
      {
        path: '/reports',
        name: '报表管理',
        component: () => import('@/views/reports/index.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
